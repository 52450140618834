#reactNotifications .dropdown-menu {
  width: 350px;
}
@media (max-width: 992px) {
  #reactNotifications {
    padding: 14px;
  }
  #reactNotifications #reactNotificationsLink i {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  #reactNotifications .dropdown {
    position: unset;
  }
  #reactNotifications .dropdown-menu {
    width: calc(100vw - 30px);
    left: 15px;
    margin-top: -10px;
  }
}
#reactNotifications .message-block {
  display: flex;
  flex-direction: row;
  border-left: 5px solid transparent;
  padding: 0;
  margin: 0;
  color: #555555;
  cursor: pointer;
  background-color: #fff;
}
#reactNotifications .message-block:hover {
  opacity: 0.8;
}
#reactNotifications .message-block.important {
  color: #E63127;
  border-color: #E63127;
}
#reactNotifications .message-block.important .message-author {
  color: inherit;
}
#reactNotifications .message-block.important .message-date {
  color: #555555;
}
#reactNotifications .message-block .left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 45px;
  padding: 0px 5px;
}
#reactNotifications .message-block .message-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  color: #1D90E0;
}
#reactNotifications .message-block .left-side + .message-content {
  position: relative;
  padding-left: 0;
  text-align: left;
  color: inherit;
}
#reactNotifications .message-block .message-title {
  font-weight: bold;
}
#reactNotifications .message-block .message-date {
  color: #A9ABC0;
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-weight: bold;
}
#reactNotifications .message-block .message-author {
  color: #A9ABC0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  white-space: nowrap;
}
#reactNotifications .message-block + .message-block {
  border-top: 1px solid #F2F2F2;
}
#reactNotifications .indicator {
  padding: 2px 4px;
  font-size: 0.8rem;
}